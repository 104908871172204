import localForage from "localforage";
import { validateKeyPair, parseKeyPair } from "./keys";

import { List } from '@/types/List';
import { ListGroup, StoredGroup } from '@/types/ListGroup';
import { useEventsBus } from '@/functions/utility';
import { KeyPair } from "@/types/KeyPair";
import { FeedArticle } from "@/types/FeedArticle";
import { ActionResponse } from "@/types/Response";

const { emit } = useEventsBus();

// setup necessary localForage stores
const dbName = 'ology';
const lf = {
    config: localForage.createInstance({
        name: dbName,
        storeName: 'config'
    }),
    list: localForage.createInstance({
        name: dbName,
        storeName: 'list'
    }),
    group: localForage.createInstance({
        name: dbName,
        storeName: 'group'
    })
};

/**
* Return a List of DIDS from the Config
*
* @return list
*/
async function getAllDids(): Promise<any> {
    const list: any[] = [];
    await lf.config.iterate((value: any) => {
        list.push(value.identity.didDoc)
    });

    return list;
}

/**
* Return a Specific DID from the config
*
* @param did DID to find
* @return DID Document
*/
async function getDid(did: string): Promise<any> {
    const config: any = await lf.config.getItem(did);
    const didDoc = config.identity.didDoc;

    return didDoc;
}

/**
* Get all available key pairs
*
* @return list of all key pairs
*/
async function getAllKeyPairs(): Promise<KeyPair[]> {
    const list: KeyPair[] = [];
    await lf.config.iterate((value: any) => {
        for (let i = 0; i < value.identity.keyPairs.length; i++) {
            const pair = value.identity.keyPairs[i];

            list.push(pair)
        }
    });

    return list;
}

/**
*  Get all available keypairs with specified perms
*/
async function getKeyPairs(perm = ''): Promise<KeyPair[]> {
    const keyPairList: KeyPair[] = []
    const configs = await getAllConfigs();
    for (let i = 0; i < configs.length; i++) {
        const config = configs[i];

        // find all keys that are granted the specified perms
        const kidList = [];
        const authList = config.identity.didDoc.psqr.permissions;
        for (let ii = 0; ii < authList.length; ii++) {
            const authItem = authList[ii];

            if (authItem.grant.includes(perm)) {
                kidList.push(authItem.kid);
            }
        }

        // add in the keys
        const keyPairs = config.identity.keyPairs;
        for (let iii = 0; iii < keyPairs.length; iii++) {
            const pair = keyPairs[iii];

            if (kidList.includes(pair.kid)) {
                keyPairList.push(pair);
            }
        }
    }

    return keyPairList;
}

/**
* Locate a Key Pair based on provided KID
*
* @param kid KID
* @return False or Key Pair
*/
async function getKeyPair(kid: string): Promise<false | KeyPair> {
    const matches = kid.match(/[^#]+/g);

    if (matches === null) {
        return false;
    }

    const did = matches[0];
    const config: any = await lf.config.getItem(did);

    for (let i = 0; i < config.identity.keyPairs.length; i++) {
        const pair = config.identity.keyPairs[i];

        if (pair.kid === kid) {
            return pair;
        }
    }

    return false;
}

/**
* Delete Key Pair associated with KID
*
* @param kid KID
*/
async function deleteKeyPair(kid: string): Promise<any> {
    const matches = kid.match(/[^#]+/g);
    if (matches === null) return false;
    const did = matches[0];
    const config: any = await lf.config.getItem(did);

    for (let i = 0; i < config.identity.keyPairs.length; i++) {
        const pair = config.identity.keyPairs[i];

        if (pair.kid === kid) {
            config.identity.keyPairs.splice(i, 1);
        }
    }

    return await putConfig(did, config);
}

/**
* Import a new config
*
* @param config
* @return Success or Failure Response Object
*/
async function importConfig(config: any): Promise<ActionResponse> {
    // ensure identity config is present
    if (typeof config.identity === 'undefined') {
        const message = 'Identity object not present in config';
        return {success: false, message};
    }

    // ensure didDoc is present
    if (typeof config.identity.didDoc === 'undefined') {
        const message = 'DID doc not present in config';
        return {success: false, message};
    }

    // parse any keys provided
    if (typeof config.identity.keyPairs !== 'undefined' && config.identity.keyPairs.length > 0) {
        const pairs = config.identity.keyPairs;
        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i];

            const parseResp = await parseKeyPair(pair);
            if (parseResp.success === false) {
                return {success: false, message: parseResp.message};
            }
            const pairObject = parseResp.pair;

            const validResp = await validateKeyPair(pairObject);
            if (validResp === false) {
                return {success: false, message: `${pair.kid} Key Pair is invalid`};
            }

            config.identity.keyPairs[i] = pairObject;
        }
    }

    // store main config
    const storeResp = await putConfig(config.identity.didDoc.id, {
        name: config.name,
        identity: config.identity,
        network: config.network
    });

    if (storeResp === null) {
        return {
            success: false,
            message: 'Unable to put config'
        }
    }

    // store any feeds present
    if (typeof config.feeds !== 'undefined' && config.feeds.length > 0) {
        let saved: any = false;
        try {
            saved = JSON.parse(localStorage.getItem("feed_configuration") || '');
        } catch (error) {
            console.error(error);

            return {
                success: false,
                message: `Unable to parse feed config`
            }
        }

        if (saved !== false) {
            for (let j = 0; j < config.feeds.length; j++) {
                const feed: any = config.feeds[j];
                saved.feeds[feed.slug] = feed;
            }

            localStorage.setItem("feed_configuration", JSON.stringify(saved));
            emit('newFeedConfig', 'config-import');
        }
    }

    // store any lists present
    if (typeof config.lists !== 'undefined' && config.lists.length > 0) {
        for (let k = 0; k < config.lists.length; k++) {
            const list = config.lists[k];

            const resp = await putList(list);

            if (resp === null) {
                return {
                    success: false,
                    message: `Unable to put list ${list.name}`
                }
            }
        }
    }

    // store any list groups present
    if (typeof config.listGroups !== 'undefined' && config.listGroups.length > 0) {
        for (let l = 0; l < config.listGroups.length; l++) {
            const listGroup = config.listGroups[l];

            const resp = await putListGroup(listGroup);

            if (resp === null) {
                return {
                    success: false,
                    message: `Unable to put list group ${listGroup.name}`
                }
            }
        }
    }

    return {
        success: true,
        message: 'Successfully put config'
    };
}

/**
* Return all configs
*
* @return List
*/
async function getAllConfigs(): Promise<any> {
    const configs: any[] = [];

    await lf.config.iterate((value: any) => {
        configs.push(value)
    });

    return configs;
}

/**
* Retrieve a specific config based on DID
*
* @param did DID
* @return config
*/
async function getConfig(did: string): Promise<any> {
    const config = await lf.config.getItem(did);

    return config;
}

/**
* Update or Create a new Config
*
* @param did DID to be associated with
* @param config config to create
* @return Success or Failure Response
*/
async function putConfig(did: string, config: any): Promise<any> {
    const putResp = await lf.config.setItem(did, config);

    return putResp;
}

/**
* Delete a config associated with a DID
*
* @param did DID
* @return Success or Failure Response
*/
async function deleteConfig(did: string): Promise<any> {
    const delResp = await lf.config.removeItem(did);

    return delResp;
}

/**
* Iterate over and return all lists
*
* @return all lists found
*/
async function getAllLists(): Promise<List[]> {
    const list: List[] = [];

    await lf.list.iterate((value: any) => {
        list.push(value)
    });

    return list;
}

/**
* Retrieve a list based on its name
*
* @param name List Name
* @return empty array or found list
*/
async function getList(name: string): Promise<List[]> {
    const listKey = parseListKey(name);
    const storedList: List | false = await lf.list.getItem(listKey) || false;

    if (storedList == false) return [];
    const list = storedList;

    return [list];
}

/**
* Import a List from a URL Endpoint
*
* @param name List Name
* @param endpoint URL Endpoint
* @param key List Key
* @param items List Items
* @return Success or Failure Message Object
*/
async function importList(name: string, endpoint: string, key: string, items = ''): Promise<ActionResponse> {
    const articleList: any[] = [];
    const filterList: string[] = [];
    const list: List = {
        name: name,
        url: endpoint,
        key: key,
        articles: articleList,
        filters: filterList
    }

    // parse list
    if (items.length > 0) {
        try {
            const rawList = items.split("\n");
            for (let i = 0; i < rawList.length; i++) {
                const item = rawList[i];
                if (item === '') continue;
                const parsedItem = JSON.parse(item);

                list.articles.push(parsedItem);
            }
        } catch (error: any) {
            return {
                success: false,
                message: `Error importing list: ${error.message}`
            }
        }
    }

    const putResp = await putList(list);

    if (putResp.articles.length === list.articles.length) {
        return {
            success: true,
            message: 'Successfully imported list'
        }
    } else {
        return {
            success: false,
            message: 'List import failed'
        }
    }
}

/**
* Create or Update a List
*
* @param list
* @return Success or Failure Response
*/
async function putList(list: List): Promise<List> {
    const listKey = parseListKey(list.name);
    const putResp = await lf.list.setItem(listKey, list);

    return putResp;
}

/**
* Add a Article to a List
*
* @param name List Name
* @param article article to add
* @return Success or Failure Response
*/
async function addArticleToList(name: string, article: FeedArticle): Promise<any> {
    const listKey = parseListKey(name);
    const listResp: null | List = await lf.list.getItem(listKey);
    if (listResp == null) return null;
    const list: List = listResp;

    // only add if did is not in block list
    if (list.filters.includes(article.identity)) return null;

    // add article and set list
    list.articles.unshift(article);
    const putResp = await lf.list.setItem(listKey, list);

    return putResp;
}

/**
* Delete a List with given name
*
* @param name List Name
* @return Success or Failure Response
*/
async function deleteList(name: string): Promise<any> {
    const listKey = parseListKey(name);
    const delResp = await lf.list.removeItem(listKey);

    return delResp;
}

/**
* Parse List Key to Lower Case and Replace Spaces with Dashes
*
* @param name List Name
* @return List Key
*/
function parseListKey(name: string): string {
    const listKey = name.toLocaleLowerCase().replace(/ /g, '-');

    return listKey;
}

/**
* Iterate over and return all list groups
*
* @return all list groupss found
*/
async function getAllListGroups(resolveLists = true): Promise<(ListGroup | StoredGroup)[]> {
    const groups: (ListGroup | StoredGroup)[] = [];

    await lf.group.iterate(async (value: StoredGroup) => {
        const grp = resolveLists ? await resolveListGroup(value) : value;
        groups.push(grp);
    });

    return groups;
}

/**
* Retrieve a list based on its name
*
* @param name List Name
* @return empty array or found list
*/
async function getListGroup(name: string, resolveLists = true): Promise<(ListGroup | StoredGroup)[]> {
    const groupKey = parseListKey(name);
    const storedGroup: StoredGroup | false = await lf.group.getItem(groupKey) || false;

    if (storedGroup == false) return [];
    const group = resolveLists ? await resolveListGroup(storedGroup) : storedGroup;

    return [group];
}

async function resolveListGroup(storedGroup: StoredGroup) {
    const resolvedGroup: ListGroup = {
        name: storedGroup.name,
        lists: []
    }

    for (let i = 0; i < storedGroup.listNames.length; i++) {
        const name = storedGroup.listNames[i];
        const list = await getList(name);

        if (list.length > 0) resolvedGroup.lists.push(list[0]);
    }

    return resolvedGroup;
}

/**
* Create or Update a List Group
*
* @param listGroup
* @return Success or Failure Response
*/
async function putListGroup(listGroup: ListGroup | StoredGroup): Promise<any> {
    const groupKey = parseListKey(listGroup.name);

    let storedGroup: StoredGroup;
    if ('lists' in listGroup) {
        storedGroup = {
            name: listGroup.name,
            listNames: listGroup.lists.map((l: List) => l.name)
        }
    } else {
        storedGroup = listGroup;
    }

    const putResp = await lf.group.setItem(groupKey, storedGroup);

    return putResp;
}

/**
* Add a List to a Group
*
* @param name Group Name
* @param listName name of list to add
* @return Success or Failure Response
*/
async function addListToGroup(name: string, listName: string): Promise<any> {
    const groupKey = parseListKey(name);
    const group: StoredGroup | null = await lf.group.getItem(groupKey);

    if (group === null) return false;
    if (group.listNames.indexOf(listName) > -1) return false;
    group.listNames.unshift(listName);

    const putResp = await lf.group.setItem(groupKey, group);

    return putResp;
}

/**
* Remove a List from a Group
*
* @param name Group Name
* @param listName name of list to remove
* @return Success or Failure Response
*/
async function removeListFromGroup(name: string, listName: string): Promise<any> {
    const groupKey = parseListKey(name);
    const group: StoredGroup | null = await lf.group.getItem(groupKey);
    if (group === null) return false;

    const index = group.listNames.indexOf(listName);
    if (index == -1) return false;
    group.listNames.splice(index, 1);

    const putResp = await lf.group.setItem(groupKey, group);

    return putResp;
}

/**
* Delete a List group with given name
*
* @param name List Group Name
* @return Success or Failure Response
*/
async function deleteListGroup(name: string): Promise<any> {
    const groupKey = parseListKey(name);
    const delResp = await lf.group.removeItem(groupKey);

    return delResp;
}

export {
    lf, getAllDids, getDid,
    getKeyPairs, getAllKeyPairs, getKeyPair, deleteKeyPair,
    getAllConfigs, getConfig, importConfig, putConfig, deleteConfig,
    getAllLists, getList, putList, deleteList, importList, parseListKey, addArticleToList,
    getAllListGroups, getListGroup, putListGroup, deleteListGroup, addListToGroup, removeListFromGroup,
}
